import React from "react";
import Navbar from "../components/NavBar";
import Announcement from "../components/Announcement";
import Slider from "../components/Slider";
import Catagories from "../components/Catagories";
import Products from "../components/Products";

export const Home = () => {
  return (
    <div>
      <Announcement />
      <Navbar />
      <Slider />
      <Catagories />
      <Products />
    </div>
  );
};
