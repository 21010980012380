export const sliderItems = [
  {
    id: 1,
    img: "https://firebasestorage.googleapis.com/v0/b/storevirtueandtrade.appspot.com/o/FL.jpg?alt=media&token=81291afa-9ea2-4369-8e13-0bb9c407df48",
    title: "Front Load Matic Cream",
    desc: "Wash you clothes ...",
    bg: "F9F9F9",
  },
  {
    id: 2,
    img: "https://firebasestorage.googleapis.com/v0/b/storevirtueandtrade.appspot.com/o/WC.jpg?alt=media&token=bccadca5-9842-4a85-9b24-5fb511d4fa24",
    title: "White Load Matic Cream",
    desc: "Wash you clothes ...",
    bg: "ECC5FB",
  },
  {
    id: 3,
    img: "https://firebasestorage.googleapis.com/v0/b/storevirtueandtrade.appspot.com/o/TL.jpg?alt=media&token=45c5f1e3-f0fd-45a8-beab-f058b3eaffc9",
    title: "Top Load Matic Cream",
    desc: "Wash you clothes ...",
    bg: "CDF0EA",
  },
];

// export const sliderItems = [
//   {
//     id: 1,
//     img: "https://i.ibb.co/XsdmR2c/1.png",
//     title: "SUMMER SALE",
//     desc: "DON'T COMPROMISE ON STYLE! GET FLAT 30% OFF FOR NEW ARRIVALS.",
//     bg: "f5fafd",
//   },
//   {
//     id: 2,
//     img: "https://i.ibb.co/DG69bQ4/2.png",
//     title: "AUTUMN COLLECTION",
//     desc: "DON'T COMPROMISE ON STYLE! GET FLAT 30% OFF FOR NEW ARRIVALS.",
//     bg: "fcf1ed",
//   },
//   {
//     id: 3,
//     img: "https://i.ibb.co/cXFnLLV/3.png",
//     title: "LOUNGEWEAR LOVE",
//     desc: "DON'T COMPROMISE ON STYLE! GET FLAT 30% OFF FOR NEW ARRIVALS.",
//     bg: "fbf0f4",
//   },
// ];

export const categories = [
  {
    id: 1,
    img: "https://firebasestorage.googleapis.com/v0/b/storevirtueandtrade.appspot.com/o/FL.jpg?alt=media&token=81291afa-9ea2-4369-8e13-0bb9c407df48",
    title: "DETERGENTS",
    cat: "DETERGENTS",
  },
  {
    id: 2,
    img: "https://images.unsplash.com/photo-1520301255226-bf5f144451c1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1173&q=80",
    title: "AQUARIAUM FISHES AND ETC...",
    cat: "AQUA",
  },
  {
    id: 3,
    img: "https://images.unsplash.com/photo-1601600576337-c1d8a0d1373c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80",
    title: "FOOD PRODUCTS",
    cat: "Virtue",
  },
];

export const popularProducts = [
  {
    id: 1,
    img: "https://firebasestorage.googleapis.com/v0/b/storevirtueandtrade.appspot.com/o/FL.jpg?alt=media&token=81291afa-9ea2-4369-8e13-0bb9c407df48",
  },
  {
    id: 2,
    img: "https://firebasestorage.googleapis.com/v0/b/storevirtueandtrade.appspot.com/o/FL.jpg?alt=media&token=81291afa-9ea2-4369-8e13-0bb9c407df48",
  },
  {
    id: 3,
    img: "https://firebasestorage.googleapis.com/v0/b/storevirtueandtrade.appspot.com/o/FL.jpg?alt=media&token=81291afa-9ea2-4369-8e13-0bb9c407df48",
  },
  {
    id: 4,
    img: "https://firebasestorage.googleapis.com/v0/b/storevirtueandtrade.appspot.com/o/FL.jpg?alt=media&token=81291afa-9ea2-4369-8e13-0bb9c407df48",
  },
  {
    id: 5,
    img: "https://firebasestorage.googleapis.com/v0/b/storevirtueandtrade.appspot.com/o/FL.jpg?alt=media&token=81291afa-9ea2-4369-8e13-0bb9c407df48",
  },
  {
    id: 6,
    img: "https://firebasestorage.googleapis.com/v0/b/storevirtueandtrade.appspot.com/o/FL.jpg?alt=media&token=81291afa-9ea2-4369-8e13-0bb9c407df48",
  },
  {
    id: 7,
    img: "https://firebasestorage.googleapis.com/v0/b/storevirtueandtrade.appspot.com/o/FL.jpg?alt=media&token=81291afa-9ea2-4369-8e13-0bb9c407df48",
  },
  {
    id: 8,
    img: "https://firebasestorage.googleapis.com/v0/b/storevirtueandtrade.appspot.com/o/FL.jpg?alt=media&token=81291afa-9ea2-4369-8e13-0bb9c407df48",
  },
];
